import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'

import MainLayout from '../components/MainLayout'
import AboutUs from '../components/AboutUs'
import Demo from '../components/Demo'
import { EGAnalytics, analyticEvents } from '../services/analytics'
import { getCmsContentType } from '../services/util'

export default (props) => {
  const headerData = getCmsContentType(props, process.env.GATSBY_CONTENTFUL_ID_HOME)
  const data = _.get(props, 'data.allContentfulAboutUs.nodes[0]')

  useEffect(() => {
    EGAnalytics.track(analyticEvents.ABOUT_US_VIEWED)
  }, [])

  return (
    <MainLayout data={headerData }>
      <AboutUs data={data} />
      <Demo />
    </MainLayout>
  )
}


export const pageQuery = graphql`
  query AboutUsPage {
    allContentfulHomeV3 {
      edges {
        node {
          title
          contentful_id
          hero {
            cta
          }
        }
      }
    }
    allContentfulAboutUs {
      nodes {
        mainTitle {
          mainTitle
        }
        aboutUsText {
          aboutUsText
        }
        backedBy {
          file {
            url
          }
        }
      }
    }
  }
`
