import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const BackedBy = ({ images }) => {
  return (
    <div className='eg-backed-by'>
      <div className='title'>Backed by</div>
      <div className='content'>
        {
          images.map((image, i) => {
            return (
              <div className='logo-wrapper' key={ i }> 
                <img className='logo' src={image.file.url} alt='investor logo'/>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

BackedBy.propTypes = {
  images: PropTypes.array.isRequired, 
}

export default BackedBy

