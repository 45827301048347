import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

const Loader = ({className = ''}) => {
  return (
    <div className={className}>
      <div className={styles.idsEllipsis}>
        <div/>
        <div/>
        <div/>
        <div/>
      </div>
    </div>
  )
}

Loader.propTypes = {
  className: PropTypes.string,
}

export default Loader
