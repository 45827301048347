import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { globalHistory } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import _ from 'lodash'

import StatelessModal from '../StatelessModal'
import { Input } from '../FormComponents'
import EmailConfirmation from './EmailConfirmation'
import Loader from '../Loader'
import { EGAnalytics, analyticEvents } from '../../services/analytics'
import { gtagReportConversion } from '../../services/util'
import { GTAG_SEND_IDS, ZSF_GOOGLE_PIXEL_IDS } from '../../constant'

import './styles.scss'

const EMAIL_FIELD = 'email'

const initialState = {
  [EMAIL_FIELD]: '',
  loading: false,
  success: false,
  error: false,
  hasSignupStarted: false,
}

const tracklinkedin = () => {
  if(process.env.GATSBY_APP_ENV !== 'dev') {
    window.lintrk && window.lintrk('track', { conversion_id: process.env.GATSBY_LINKEDIN_CONVERSION_ID})
  }
}

const EmailBox = props => {
  const { cta, onSubmit, section } = props
  const [emailData, setEmailData] = useState(initialState)
  const { location } = globalHistory
  const leadGenContentfulData = useStaticQuery(graphql`
    query LeadGenQuery {
      allContentfulLeads {
        edges {
          node {
            urlSlug
          }
        }
      }
    }
  `)
  const leadGenUrl = _.get(leadGenContentfulData, 'allContentfulLeads.edges[0].node.urlSlug')
  const onFocus = () => {
    setEmailData({...emailData, error: false, success: false, hasSignupStarted: false})
  }

  const handleInputChange = (event) => {
    if(!emailData.hasSignupStarted) {
      EGAnalytics.track(analyticEvents.SIGNUP_STARTED, { page: location.pathname, section })
    }
    setEmailData({...emailData, hasSignupStarted: true, [event.target.name]: event.target.value})
  }

  const handleSubmit = (event) => {
    gtagReportConversion(GTAG_SEND_IDS.EMAIL_SUBMISSION_SEND_ID)
    gtagReportConversion(ZSF_GOOGLE_PIXEL_IDS.EMAIL_SUBMISSION_SEND_ID)
    setEmailData({ ...emailData, loading: true})
    event.preventDefault()
    const form = event.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        EGAnalytics.track(analyticEvents.SIGNUP_COMPLETED, {
          page: location.pathname,
          userId_: emailData.email,
          section
        })
        tracklinkedin()
        if(leadGenUrl){
          window.location.href = `${leadGenUrl}?email=${emailData.email}`
        } else {
          // In case if we don't have leadGenURl we will use normal email submission flow
          setEmailData({...emailData, loading: false, success: true})
        }
      } else {
        setEmailData({...emailData, loading: false, error: true})
      }
    }
    xhr.send(data)
    if (onSubmit) {
      onSubmit()
    }
  }

  const onCloseConfirmationModal = () => {
    setEmailData({...emailData, error: false, success: false})
  }

  if(emailData.loading) return <Loader className='eg-email-loader' />

  return (
    <>
      <form className='eg-email-box'
        onSubmit={handleSubmit}
        action={process.env.GATSBY_FORM_SUBMIT_URL}
        method="POST">
        <Input
          className='email-input'
          placeholder='Your work email'
          type='email'
          name={EMAIL_FIELD}
          value={emailData[EMAIL_FIELD]}
          onChange={handleInputChange}
          onFocus= {onFocus}
          required={true}
        />
        <input type='hidden' name='source' value={ location.pathname } />
        <button type='submit' className='cta'>{cta}</button>
      </form>
      {emailData.error && <div className='eg-email-error' >Something went wrong, Please try again with submitting.</div> }
      {emailData.success
        && (
          <StatelessModal onClose = { onCloseConfirmationModal } >
            <EmailConfirmation />
          </StatelessModal>
        )
      }
    </>
  )
}

EmailBox.propTypes = {
  cta: PropTypes.string.isRequired,
  section: PropTypes.string,
  onSubmit: PropTypes.func
}

export default EmailBox
