import React from 'react'
import { Helmet } from 'react-helmet'

import { openIntercom } from '../../../services/util'
import './styles.scss'

const EmailConfirmation = props => {
  return (
    <div className='eg-email-confirmation'>
      <div className='logo-container'>
        <div className='logo'/>
      </div>
      <div className='thank' >Thank you! </div>
      <div className='text'>
        We'll get back to you with a demo shortly. Please check your email for next steps.
      </div>
      <div className='help'>
        Need help?
        <span onClick={ openIntercom }>Contact us</span>
      </div>
      <Helmet>
        <script src="//action.dstillery.com/orbserv/nsjs?adv=cl1030732&ns=5843&nc=Converter&ncv=55&dstOrderId=[OrderId]&dstOrderAmount=[OrderAmount]" type="text/javascript" defer={ true }></script>
      </Helmet>
    </div>
  )
}

export default EmailConfirmation
