import React from 'react'
import PropTypes from 'prop-types'


import BackedBy from './BackedBy'
import './styles.scss'


const AboutUs = ({data}) => {
  return (
    <div className='eg-about-us'>
      <div className='hero'>
        <div className='text'>
          {data.mainTitle.mainTitle}
        </div>
      </div>
      <article>
        <div className='title'>
          About us 
        </div>
        <p >
          {data.aboutUsText.aboutUsText}
        </p>
      </article>
      <BackedBy images={data.backedBy}/>
    </div>
  )
}

AboutUs.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutUs
