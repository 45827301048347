import React from 'react'
import PropTypes from 'prop-types'
import { globalHistory } from '@reach/router'


import { EGAnalytics, analyticEvents } from '../../services/analytics'
import EmailBox from '../EmailBox'
import './styles.scss'

const Demo = ({ data }) => {
  const { location } = globalHistory

  const title = data ? data.title : 'Capture customer reviews from every app'
  const subtitle = data ? data.subHeader : 'Request for a demo now'
  const buttonCta = data ? data.cta : 'Get a demo'

  const handleSubmit = () => {
    EGAnalytics.track(analyticEvents.CTA_INTERACTION, {
      page: location.pathname,
      section: 'Get Demo',
      ctaLabel: buttonCta,
    })
  }

  return (
    <section className='eg-demo' id='eg-demo-section'>
      <div className='inner'>
        <div className='left-part'>
          <div className='title'>
            {title}
          </div>
          <div className='subtitle'>
            {subtitle}
          </div>
        </div>
        <div className='right-part'>
          <EmailBox cta= {buttonCta} onSubmit={ handleSubmit } section='Get Demo' />
        </div>
      </div>
    </section>
  )
}

Demo.propTypes = {
  data: PropTypes.object,
}

export default Demo
